import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal purchase-detail-confirm multifocal-upgrade",
  role: "dialog"
}
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "callout" }
const _hoisted_5 = { class: "right" }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "content-wrapper" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "box-wrapper" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "left" }
const _hoisted_13 = ["textContent"]
const _hoisted_14 = { class: "content-wrapper" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "box-wrapper" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "earning-details-container" }
const _hoisted_20 = { class: "base-subtotal subtotal-container base-reward-subtotal" }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { class: "subtotal-amount has-color-light-blue" }
const _hoisted_24 = {
  key: 0,
  class: "bonus-subtotal subtotal-container bonus-reward-subtotal"
}
const _hoisted_25 = { class: "subtotal-description" }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = { class: "subtotal-amount has-color-light-blue" }
const _hoisted_28 = { class: "total subtotal-container total-reward-subtotal" }
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { class: "subtotal-amount has-color-dark-blue" }
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = { class: "button-container" }
const _hoisted_33 = { class: "disclaimer-container" }
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = ["alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PreferredTooltip = _resolveComponent("PreferredTooltip")
  const _component_PromoCodeForm = _resolveComponent("PromoCodeForm")

  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: "modal-overlay",
    onClick: _cache[4] || (_cache[4] = _withModifiers($event => ($options.cancelAction()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($data.dataLoaded)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("h2", {
              textContent: _toDisplayString($setup.t('purchase_detail_confirm.header', { name: _ctx.profile.first_name }))
            }, null, 8, _hoisted_2),
            _createElementVNode("p", {
              class: "featured purchase-detail-confirm-intro",
              textContent: _toDisplayString($setup.t('purchase_detail_confirm.body'))
            }, null, 8, _hoisted_3),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h4", {
                  textContent: _toDisplayString($setup.t('purchase_detail_confirm.inner_body.right_header'))
                }, null, 8, _hoisted_6),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("p", {
                    class: "product-purchased",
                    innerHTML: $setup.t('purchase_detail_confirm.inner_body.product_purchased_header', {
                                    product_purchased: $options.brandCopy[_ctx.purchase.supplies.brand_r],
                                })
                  }, null, 8, _hoisted_8),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("span", {
                      class: "purchase-lenses-per-box",
                      innerHTML: $setup.t('purchase_detail_confirm.inner_body.lenses_per_box_header', {
                                        lenses_per_box: _ctx.purchase.supplies.lenses_per_box_r,
                                    })
                    }, null, 8, _hoisted_10),
                    _createElementVNode("span", {
                      class: "purchase-box",
                      innerHTML: $setup.t('purchase_detail_confirm.inner_body.boxes_purchased_header', {
                                        boxes: _ctx.purchase.supplies.boxes_r,
                                    })
                    }, null, 8, _hoisted_11)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("h4", {
                  textContent: _toDisplayString($setup.t('purchase_detail_confirm.inner_body.left_header'))
                }, null, 8, _hoisted_13),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("p", {
                    class: "product-purchased",
                    innerHTML: $setup.t('purchase_detail_confirm.inner_body.product_purchased_header', {
                                    product_purchased: $options.brandCopy[_ctx.purchase.supplies.brand_l],
                                })
                  }, null, 8, _hoisted_15),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("span", {
                      class: "purchase-lenses-per-box",
                      innerHTML: $setup.t('purchase_detail_confirm.inner_body.lenses_per_box_header', {
                                        lenses_per_box: _ctx.purchase.supplies.lenses_per_box_l,
                                    })
                    }, null, 8, _hoisted_17),
                    _createElementVNode("span", {
                      class: "purchase-box",
                      innerHTML: $setup.t('purchase_detail_confirm.inner_body.boxes_purchased_header', {
                                        boxes: _ctx.purchase.supplies.boxes_l,
                                    })
                    }, null, 8, _hoisted_18)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                (_ctx.purchase.supplies.brand_l == _ctx.purchase.supplies.brand_r)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "subtotal-description",
                      innerHTML: $setup.t('purchase_complete.mail_confirm.base_reward_copy', {
                                supplyLength: $options.getSupplyCopy(),
                                productDescription: $options.brandCopy[_ctx.purchase.supplies.brand_l || _ctx.purchase.supplies.brand_r],
                            })
                    }, null, 8, _hoisted_21))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "subtotal-description",
                      innerHTML: $setup.t('purchase_complete.mail_confirm.base_reward_copy_both', {
                                supplyLength: $options.getSupplyCopy(),
                                productDescriptionLeft: $options.brandCopy[_ctx.purchase.supplies.brand_l],
                                productDescriptionRight: $options.brandCopy[_ctx.purchase.supplies.brand_r],
                            })
                    }, null, 8, _hoisted_22)),
                _createElementVNode("div", _hoisted_23, [
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                  _createTextVNode(_toDisplayString($data.result?.rewards?.baseReward), 1),
                  _cache[6] || (_cache[6] = _createElementVNode("sup", null, " ‡", -1))
                ])
              ]),
              ($options.isPreferredBonusEligible() && $options.bonusAmount > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("span", {
                        innerHTML: $setup.t('purchase_complete.mail_confirm.bonus_reward_copy')
                      }, null, 8, _hoisted_26),
                      _createVNode(_component_PreferredTooltip, { icon: "<img src='/public/img/question-circle.png' class='icon-question-circle' width='12' height='12'>" })
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                      _createTextVNode(_toDisplayString($options.bonusAmount), 1),
                      _cache[8] || (_cache[8] = _createElementVNode("sup", null, " ", -1))
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_PromoCodeForm, {
                ref: "PromoCodeForm",
                "submission-id": $props.submissionId,
                "previous-promocode-applied": $data.previousPromocodeApplied,
                onPromocodeApplied: _cache[0] || (_cache[0] = $event => ($options.onPromocodeApplied($event)))
              }, null, 8, ["submission-id", "previous-promocode-applied"]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", {
                  class: "subtotal-description",
                  innerHTML: $setup.t('purchase_complete.mail_confirm.total_reward_copy')
                }, null, 8, _hoisted_29),
                _createElementVNode("div", _hoisted_30, [
                  _cache[9] || (_cache[9] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                  _createTextVNode(_toDisplayString($options.totalAmount), 1),
                  _cache[10] || (_cache[10] = _createElementVNode("sup", null, " ", -1))
                ])
              ])
            ]),
            _createElementVNode("p", {
              class: "featured",
              innerHTML: $setup.t('purchase_detail_confirm.reward_results.body_copy2')
            }, null, 8, _hoisted_31),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("button", {
                "data-e2e": "purchase-detail-confirm-modal-close",
                class: "with-right-arrow",
                onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.goToReceiptUpload()), ["prevent"]))
              }, _toDisplayString($setup.t('buttons:next_button')), 1),
              _createElementVNode("button", {
                class: "tertiary with-left-arrow",
                type: "button",
                onClick: _cache[2] || (_cache[2] = $event => ($options.cancelAction()))
              }, _toDisplayString($setup.t('buttons:cancel')), 1)
            ]),
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("p", {
                innerHTML: $setup.t('disclaimer.purchase_copy_old', { terms_link: $options.termsLink, ...$options.prepaidCard, interpolation: { escapeValue: false } })
              }, null, 8, _hoisted_34)
            ])
          ], 64))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => ($options.cancelAction()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "icon-close",
          alt: $setup.t('buttons:close')
        }, null, 8, _hoisted_35)
      ])
    ])
  ], 512))
}