import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal faq-modal",
  role: "dialog"
}
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("template", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", {
            innerHTML: $setup.t('2steps.headline_copy')
          }, null, 8, _hoisted_3),
          _createElementVNode("div", {
            innerHTML: $setup.t('2steps.body_copy', { siteURL: _ctx.app.siteURL })
          }, null, 8, _hoisted_4),
          _createElementVNode("button", {
            class: "with-right-arrow",
            onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"])),
            innerHTML: $setup.t('2steps.btn_copy')
          }, null, 8, _hoisted_5)
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "icon-close",
          alt: $setup.t('buttons:close')
        }, null, 8, _hoisted_6)
      ])
    ])
  ], 512))
}