import I18NextVue from 'i18next-vue';
import { createApp, h } from 'vue';

import { i18next, i18nextDirective } from '../i18n';
import router from '../router';
import store from '../store';

export default function openModal (component, props) {
    return new Promise((resolve, reject) => {
        const modalApp = createApp({
            data () {
                return {
                    modalVNode: null,
                };
            },

            methods: {
                closeHandler (fn, arg) {
                    fn(arg);

                    const modal =
                        this.modalVNode?.component?.expose ||
                        this.modalVNode?.component?.proxy;
                    modal.destroyModal();
                },
            },

            render () {
                this.modalVNode = h(
                    component,
                    {
                        ...props,
                        onClose: () => {
                            modalApp.unmount();
                        },
                        onCancelModal: (arg) => this.closeHandler(reject, arg),
                        onCloseModal: (arg) => this.closeHandler(resolve, arg),
                    },
                );

                return this.modalVNode;
            },
        });

        // Modals are started up as a completely separate Vue app; we add some
        // properties here so they have the same functionality as the main app.
        modalApp.use(I18NextVue, { i18next });
        modalApp.directive('t', i18nextDirective);
        modalApp.use(router);
        modalApp.use(store);

        const containerDiv = document.createElement('div');
        return modalApp.mount(containerDiv);
    });
}

export { default as AddressVerificationModal } from './AddressVerificationModal.vue';
export { default as ApprovalConfirmationModal } from './ApprovalConfirmationModal.vue';
export { default as CommentAdminModal } from './CommentAdminModal.vue';
export { default as ConfirmPurchaseModal } from './ConfirmPurchaseModal.vue';
export { default as DoctorNotListedModal } from './DoctorNotListedModal.vue';
export { default as EditPatientConfirmModal } from './EditPatientConfirmModal.vue';
export { default as EligibilityModal } from './EligibilityModal.vue';
export { default as ErrorModal } from './ErrorModal.vue';
export { default as ExpressClaimNoticeModal } from './ExpressClaimNoticeModal.vue';
export { default as EyeCareProviderModal } from './EyeCareProviderModal.vue';
export { default as GeneralMessageModal } from './GeneralMessageModal.vue';
export { default as LanguageToggleModal } from './LanguageToggleModal.vue';
export { default as LoginModal } from './LoginModal.vue';
export { default as NonParticipatingModal } from './NonParticipatingModal.vue';
export { default as OamoeOverrideModal } from './OamoeOverrideModal.vue';
export { default as PasswordModal } from './PasswordModal.vue';
export { default as PreviewPhotoModal } from './PreviewPhotoModal.vue';
export { default as PurchaseCompleteModal } from './PurchaseCompleteModal.vue';
export { default as PurchaseDetailConfirmModal } from './PurchaseDetailConfirmModal.vue';
export { default as QuestionsModal } from './QuestionsModal.vue';
export { default as RewardEstimatorModal } from './RewardEstimatorModal.vue';
export { default as SamplePhotoModal } from './SamplePhotoModal.vue';
export { default as SampleReceiptModal } from './SampleReceiptModal.vue';
export { default as ScanditErrorModal } from './ScanditErrorModal.vue';
export { default as SessionExpirationModal } from './SessionExpirationModal.vue';
export { default as SubmissionModal } from './SubmissionModal.vue';
export { default as TwoStepModal } from './TwoStepModal.vue';
export { default as VisaModal } from './VisaModal.vue';
export { default as VideoModal } from './VideoModal.vue';
