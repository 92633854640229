
// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments

const
    PurchaseInfoPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/PurchaseInfoPage.vue'
    ),
    ProductIntakePage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/ProductIntakePage.vue'
    ),
    ProductScanPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/ProductScanPage.vue'
    ),
    ReceiptUploadPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/ReceiptUploadPage.vue'
    ),
    RewardEstimatorPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/RewardEstimatorPage.vue'
    );

export default [
    { path: '/purchase_info', component: PurchaseInfoPage, meta: { prevPath: ['/patient_entry'] } },
    { path: '/purchase_info/:hasDate', component: PurchaseInfoPage, props: true, meta: { prevPath: ['/patient_entry'] } },
    { path: '/product_intake', component: ProductIntakePage, meta: { prevPath: ['/purchase_info'] } },
    { path: '/product_scan', component: ProductScanPage, meta: { prevPath: ['/purchase_info'] } },
    { path: '/receipt_upload', component: ReceiptUploadPage, meta: { prevPath: ['product_intake'] } },
    { path: '/reward_estimator', component: RewardEstimatorPage, meta: { public: true } },
];
