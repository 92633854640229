
import axios from 'axios';
import api from '../../api';

const purchaseEndpoint = 'purchase-submissions/';
const defaultState = {
    purchase_date: undefined,
    is_resubmission: false,
    status: undefined,
    rewardAmount: 0,
    bonusAmount: 0,
    confirmationCode: undefined,
    hasPreferredEcp: false,
    SKU: undefined,
    patient_type: undefined,
    product_image_url: undefined,
    receipt_image_url: undefined,
    limitRemaining: undefined,
    supplies: {
        boxes_l: '',
        boxes_r: '',
        brand_l: '',
        brand_r: '',
        lenses_per_box_l: '',
        lenses_per_box_r: '',
        submission_method: undefined,
        supplyLength: undefined,
    },
};
const state = {
    ...defaultState,
    purchaseSubmissions: [],
    expressSubmissions: [],
};

const getters = {
    hasPreferredEcp (state) {
        return state.hasPreferredEcp;
    },
    purchaseSubmissionStatus: (state) => (id) => state.purchaseSubmissions.filter((submission) => submission.patient_id === id),

    hasApprovedSubmissions (state) {
        const submissions = state.purchaseSubmissions || [];
        return !!submissions.find((submission) => submission.status === 'approved');
    },

    hasPurchaseInProgress (state, getters) {
        return (patient_id) => getters.purchaseSubmissionStatus(patient_id).find(({ status }) => !status);
    },
    hasInProgressOrRejectedPurchases (state, getters) {
        return (patient_id) => getters.purchaseSubmissionStatus(patient_id).find(({ status }) => !status || status === 'rejected' || status === 'resub_started');
    },
    hasStoredSubmission (state) {
        if (state.incompleteSubmission?.purchase_submission_id !== undefined && state.incompleteSubmission?.purchase_submission_id !== undefined) {
            return state.purchase_submission_id === state.incompleteSubmission.purchase_submission_id;
        }
        return false;
    },
    getExpressSubmissionData: state => state.expressSubmissions,
};

const mutations = {
    updateInfo (state, data) {
        if (!Array.isArray(data)) {
            data = [data];
        }

        for (const values of data) {
            for (const key in values) {
                state[key] = values[key];
            }
        }
    },
    storeCurrentExpressSubmission (state, list) {
        state.expressSubmissions = list;
    },
};

const actions = {
    resetState ({ commit }) {
        return commit('updateInfo', defaultState);
    },
    async updatePurchaseInfo ({ dispatch, commit }, data) {
        await dispatch('resetState');
        commit('updateInfo', data);
    },
    async getPatientLimit ({ dispatch, commit, rootState, state }) {
        const response = await dispatch('makeCall', {
            type: 'get',
            endpoint: 'patients/limit-remaining',
            data: {
                params: {
                    patient_id: rootState.patient.currentPatientInfo.id,
                    purchase_submission_id: rootState.patient.purchase_submission_id,
                    purchase_date: state.purchase_date,
                },
            },
        });
        const { data: { limitRemaining } } = response;
        commit('updateInfo', { limitRemaining });
        return limitRemaining;
    },
    async getPurchaseSubmissions ({ dispatch, commit, rootState }) {
        // Explicitly use profile.id if available. Addresses GW-69392 PL
        // in which impersonate operations revealed unrelated user data
        const suffix = rootState?.profile?.id || 'me';

        const response = await dispatch('makeCall', {
            type: 'get',
            endpoint: `purchase-submissions/all/${suffix}`,
        });
        commit('updateInfo', {
            purchaseSubmissions: response.data.result,
            hasPreferredEcp: response.data.hasPreferredEcp,
        });
        return response.data.result;
    },
    async getProductBySKU ({ dispatch }, { SKU }) {
        try {
            const response = await dispatch('makeCall', {
                type: 'get',
                endpoint: `${purchaseEndpoint}sku/${SKU}`,
            });
            return response.data.result;
        }
        catch (error) {
            console.error('error', error);
            return { error };
        }
    },
    async clearPurchaseDate ({ commit }) {
        commit('updateInfo', { purchase_date: undefined });
    },

    async checkPurchaseDate ({ dispatch, commit }, data) {
        try {
            const response = await dispatch('makeCall', {
                endpoint: `${purchaseEndpoint}purchase-date`,
                data,
            });

            commit('updateInfo', { purchase_date: data.purchase_date });
            commit('patient/updateInfo', { purchase_submission_id: response.data.result.purchaseSubmissionId }, { root: true });
            return response.data.result;
        }
        catch (error) {
            console.error('error', error);
            throw error;
        }
    },
    async submitPhoto ({ dispatch, rootState, commit }, tempForm) {
        const { uploadHost, uploadPath } = await dispatch('getPhotoUploaderKey', 'photo');

        try {
            const endpoint = `${uploadHost}${uploadPath}`;
            const formData = new FormData();

            const instance = axios.create({});
            // not needed for the uploader call. Causing cors issues.
            delete instance.defaults.headers.common['X-HW-Profile-Token'];
            delete instance.defaults.headers.common['X-Auth0-Access-Token'];

            const options = { headers: {
                'Content-Type': undefined,
            } };

            formData.append('upload', tempForm.file, tempForm.name);

            const response = await instance.post(endpoint, formData, options);
            return response.data.outputs.orig.url;
        }
        catch (error) {
            console.error('error', error);
            throw error;
        }
    },
    async getPhotoUploaderKey ({ rootState, dispatch }, whichType) {
        const input_data = {
            uid: rootState.profile.id,
            type: whichType,
            language: rootState.app.userLanguage,
        };
        try {
            const { data } = await axios.post(`${api.base}/uploader/key`, input_data);
            return data;
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },

    storeIncompleteSubmission ({ commit }, data) {
        const storageObject = JSON.stringify(data);
        localStorage.setItem('incompleteSubmission', storageObject);
        commit('updateInfo', { incompleteSubmission: data });
    },

    deleteIncompleteSubmission ({ commit }) {
        localStorage.removeItem('incompleteSubmission');
        commit('updateInfo', { incompleteSubmission: null });
    },

    checkForStoreSubmission ({ commit }) {
        const submission = localStorage.getItem('incompleteSubmission');
        if (submission) {
            const data = JSON.parse(submission);
            commit('updateInfo', { incompleteSubmission: data });
        }
    },

    async submitPurchaseData ({ rootState, state, dispatch, commit }, data) {
        try {
            const { purchase_submission_id, product_image_url, ...supplies } = data;
            const payload = {
                patientId: rootState.patient.currentPatientInfo.id,
                purchase_submission_id,
                product_image_url,
                supplies,
                resubmission: state.is_resubmission,
            };
            if (state.exception) {
                payload.exception = state.exception;
            }
            const response = await dispatch('makeCall', {
                endpoint: 'purchase-submissions/submit-supplies',
                data: payload,
            });
            commit('updateInfo', { supplies, product_image_url });

            return response;
        }
        catch (error) {
            console.error('error', error);
            throw error.response.data.errors;
        }
    },
    async submitReceiptData ({ dispatch, rootState, commit }, receipt_image_url) {
        try {
            const response = await dispatch('makeCall', {
                endpoint: 'purchase-submissions/submit-receipt-image',
                data: {
                    receipt_image_url,
                    purchase_submission_id: rootState.patient.purchase_submission_id,
                },
            });
            commit('updateInfo', { receipt_image_url });
            return response.data;
        }
        catch (error) {
            console.error('error', error);
        }
    },
    async completePurchaseSubmission ({ dispatch, rootState, state }) {
        try {
            const data = {
                purchase_submission_id: rootState.patient.purchase_submission_id,
            };

            if (state.is_resubmission) {
                data.resubmission = true;
            }

            const response = await dispatch('makeCall', {
                endpoint: 'purchase-submissions/confirm',
                data,
                params: { channel: rootState.app.channel },
            });
            const { data: { result: { submission: { status, rewards, confirmation_code, purchase_details } } } } = response;
            return {
                status,
                baseReward: parseInt(rewards.baseReward),
                bonusRewards: rewards.bonusRewards,
                promocodeRewards: rewards.promocodeRewards || {},
                confirmationCode: confirmation_code,
                purchase: purchase_details,
            };
        }
        catch (error) {
            console.error('error', error);
        }
    },
    async submitRewardEstimate ({ dispatch }, data) {
        try {
            const response = dispatch('makeCall', {
                endpoint: 'rewards/estimate',
                type: 'get',
                data,
            });

            return response;
        }
        catch (error) {
            console.error('error', error);
            throw error;
        }
    },
    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data = {},
        params = {},
    }) {
        try {
            const response = await axios[type](`${api.base}/${endpoint}`, data, { params });
            return response;
        }
        catch (error) {
            console.error(
                `error making ${endpoint} call`,
                error.message,
                error,
            );

            throw error;
        }
    },
    setCurrentExpressSubmission ({ commit }, list) {
        commit('storeCurrentExpressSubmission', list);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
