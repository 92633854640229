
    import { useI18next } from '@composables/i18next';
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
    import TheFooter from '@components/TheFooter';
    import { CookieGate } from '@public/vendor/cookie-gate';
    import { QuestionsIcon } from '@components/images';
    import openModal, { SessionExpirationModal, QuestionsModal } from './modals';
    import webAnalytics from './plugins/WebAnalytics';


    const scrollToTop = () => {
        try {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            window.pym.scrollParentTo('rewardsIframe');
        }
        catch (error) {
            console.warn('Unable to adjust scroll position.');
        }
    };

    export default {
        name: 'App',

        components: {
            CookieGate,
            TheFooter,
            QuestionsIcon,
        },

        provide: {
            $webAnalytics: webAnalytics,
        },

        setup () {
            const { t } = useI18next(['global', 'cookie_gate']);
            return { t };
        },

        data () {
            return {
                pageNamesWithoutHeader: ['faq', 'rapid-rewards-info', 'rapid-rewards-confirm', 'checkout-not-needed'],
                pageNamesWithoutQuestions: ['faq'],
                questionsModalOpen: false,
                pageWithLogo: ['rapid-rewards-info', 'rapid-rewards-confirm'],
                pagesThatShowRewardsChat: ['/purchase_info', '/product_intake', '/dashboard'],
                disableContactsChat: true,
                chatIds: {
                    rewards: '5733x0000000TX7',
                    contacts: '573a0000000CwAI',
                },
            };
        },


        computed: {
            ...mapState({
                app: 'app',
                profile: 'profile',
                ui: 'ui',
            }),

            ...mapGetters({
                loggedIn: 'profile/loggedIn',
                isSessionExpired: 'profile/isSessionExpired',
                isAdminImpersonation: 'profile/isAdminImpersonation',
            }),

            pageClass () {
                return this.ui.pageName;
            },

            showHeader () {
                return !this.pageNamesWithoutHeader.includes(this.ui.pageName);
            },

            showHeaderLogo () {
                return this.pageWithLogo.includes(this.ui.pageName);
            },

            showQuestionsIcon () {
                return this.loggedIn && !this.questionsModalOpen && !this.pageNamesWithoutQuestions.includes(this.ui.pageName);
            },
            showRewardsChat () {
                return this.pagesThatShowRewardsChat.includes(this.$route.path);
            },
        },

        watch: {
            isSessionExpired (value) {
                if (value) {
                    this.openSessionExpirationModal();
                }
            },
        },

        mounted () {
            this.$nextTick(() => {
                try {
                    // eslint-disable-next-line babel/new-cap
                    window.pymChild = window.pym.Child({ id: 'rewardsIframe',  polling: 500 });
                    window.pymChild.scrollParentTo('rewardsIframe');
                }
                catch (error) {
                    console.warn('Unable to adjust scroll position.');
                }
            });

            if (process.env.NODE_ENV !== 'development') {
                window.liveagent.init('https://d.la1-c1-ia5.salesforceliveagent.com/chat', '5723x0000000T52', '00D30000000mVB0');
                const rewardsChatId = '5733x0000000TX7';
                const lensAssistChatId = '573a0000000CwAI';
                if (!window._laq) { window._laq = []; }
                window._laq.push(() => {

                    window.liveagent.showWhenOnline(rewardsChatId, this.$refs.rewards_online_proxy);
                    window.liveagent.showWhenOffline(rewardsChatId, this.$refs.rewards_offline_proxy);

                    window.liveagent.showWhenOnline(lensAssistChatId, this.$refs.contact_lenses_online_proxy);
                    window.liveagent.showWhenOffline(lensAssistChatId, this.$refs.contact_lenses_offline_proxy);
                });
            }
        },

        // If a fatal error occurs rendering any of our descendent components, try
        // to navigate to the error page to preserve some UX.
        // https://vuejs.org/v2/api/#errorCaptured
        errorCaptured () {
            this.$router.push('/error');
        },

        methods: {
            ...mapMutations({
                modalClose: 'ui/modalClose',
            }),
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            async openSessionExpirationModal () {
                await this.modalClose();
                await openModal(SessionExpirationModal);

                // After the modal is closed.
                this.logOut();
                this.$router.replace('/');
            },

            async openQuestionsModal () {
                await this.modalClose();
                this.questionsModalOpen = true;
                await openModal(QuestionsModal, {
                    pageName: this.ui.pageName,
                    rewardsOnline: this.$refs.rewards_online_proxy.style.display !== 'none',
                    rewardsOffline: this.$refs.rewards_offline_proxy.style.display !== 'none',
                    contactsOnline: this.$refs.contact_lenses_online_proxy.style.display !== 'none',
                    contactsOffline: this.$refs.contact_lenses_offline_proxy.style.display !== 'none',
                    rewardsChatId: this.chatIds.rewards,
                    lensAssistChatId: this.chatIds.contacts,
                    showRewardsChat: this.showRewardsChat,
                    disableContactsChat: this.disableContactsChat,
                });
                this.questionsModalOpen = false;
                scrollToTop();
            },
        },
    };
