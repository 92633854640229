import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "callout" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "base-subtotal subtotal-container"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "subtotal-amount has-color-light-blue" }
const _hoisted_10 = {
  key: 1,
  class: "subtotal-container bonus-subtotal"
}
const _hoisted_11 = { class: "bonus-subtotal subtotal-description" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "subtotal-amount has-color-light-blue" }
const _hoisted_14 = {
  key: 2,
  class: "promocode-subtotal subtotal-container"
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "subtotal-amount has-color-light-blue" }
const _hoisted_17 = {
  key: 3,
  class: "total subtotal-container"
}
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "subtotal-amount has-color-dark-blue" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = {
  key: 0,
  class: "base-subtotal subtotal-container"
}
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = { class: "subtotal-amount has-color-light-blue" }
const _hoisted_27 = {
  key: 1,
  class: "subtotal-container bonus-subtotal"
}
const _hoisted_28 = { class: "bonus-subtotal subtotal-description" }
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { class: "subtotal-amount has-color-light-blue" }
const _hoisted_31 = {
  key: 2,
  class: "promocode-subtotal subtotal-container"
}
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = { class: "subtotal-amount has-color-light-blue" }
const _hoisted_34 = {
  key: 3,
  class: "total subtotal-container"
}
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = { class: "subtotal-amount has-color-dark-blue" }
const _hoisted_37 = ["innerHTML"]
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = ["innerHTML"]
const _hoisted_40 = ["innerHTML"]
const _hoisted_41 = ["innerHTML"]
const _hoisted_42 = ["alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MailIcon = _resolveComponent("MailIcon")
  const _component_PreferredTooltip = _resolveComponent("PreferredTooltip")
  const _component_StarIcon = _resolveComponent("StarIcon")

  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal submission-complete multifocal-upgrade", {
                'no-mail': !$options.mailRequired,
            }]),
      role: "dialog"
    }, [
      ($options.mailRequired)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("h2", {
              innerHTML: $setup.t('purchase_complete.mail_confirm.headline_copy')
            }, null, 8, _hoisted_1),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_MailIcon),
              _createElementVNode("h3", {
                innerHTML: $setup.t('purchase_complete.mail_confirm.sub_headline_copy', {
                            reward_amount: $options.totalAmount,
                            ...$options.prepaidCard, interpolation: { escapeValue: false },
                        })
              }, null, 8, _hoisted_3),
              _createElementVNode("p", {
                class: "featured confirmation-code-copy",
                innerHTML: $setup.t('purchase_complete.mail_confirm.body_copy', {
                            confirmation_code: $props.confirmationCode,
                        })
              }, null, 8, _hoisted_4),
              _createElementVNode("p", {
                class: "featured mailing-address-copy",
                innerHTML: $setup.t('purchase_complete.mail_confirm.address')
              }, null, 8, _hoisted_5),
              _createElementVNode("div", null, [
                ($props.baseReward)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      ($props.purchase.supplies.brand_l == $props.purchase.supplies.brand_r)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "subtotal-description",
                            innerHTML: $setup.t('purchase_complete.mail_confirm.base_reward_copy', { supplyLength: $options.supplyLength, productDescription: $options.productDescription })
                          }, null, 8, _hoisted_7))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "subtotal-description",
                            innerHTML: $setup.t('purchase_complete.mail_confirm.base_reward_copy_both', { supplyLength: $options.supplyLength, productDescriptionLeft: $options.productDescriptionLeft, productDescriptionRight: $options.productDescriptionRight })
                          }, null, 8, _hoisted_8)),
                      _createElementVNode("div", _hoisted_9, [
                        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                        _createTextVNode(_toDisplayString($props.baseReward), 1),
                        _cache[3] || (_cache[3] = _createElementVNode("sup", null, " ", -1))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                ($options.bonusAmount > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("span", {
                          innerHTML: $setup.t('purchase_complete.mail_confirm.bonus_reward_copy')
                        }, null, 8, _hoisted_12),
                        _createVNode(_component_PreferredTooltip, { icon: "question-circle" })
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                        _createTextVNode(_toDisplayString($options.bonusAmount), 1),
                        _cache[5] || (_cache[5] = _createElementVNode("sup", null, " ", -1))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                ($options.promocodeAmount > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", {
                        class: "subtotal-description",
                        innerHTML: $setup.t('purchase_complete.mail_confirm.promocode_reward_copy', {
                                        promocodeAmount: $options.promocodeAmount,
                                    })
                      }, null, 8, _hoisted_15),
                      _createElementVNode("div", _hoisted_16, [
                        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                        _createTextVNode(_toDisplayString($options.promocodeAmount), 1),
                        _cache[7] || (_cache[7] = _createElementVNode("sup", null, " ", -1))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                ($options.totalAmount)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("div", {
                        class: "subtotal-description",
                        innerHTML: $setup.t('purchase_complete.mail_confirm.total_reward_copy', { supplyLength: $options.supplyLength, productDescription: $options.productDescription })
                      }, null, 8, _hoisted_18),
                      _createElementVNode("div", _hoisted_19, [
                        _cache[8] || (_cache[8] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                        _createTextVNode(_toDisplayString($options.totalAmount), 1),
                        _cache[9] || (_cache[9] = _createElementVNode("sup", null, " ", -1))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                ($data.preferredFlag)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 4,
                      class: "preferred-more-info-disclaimer modal-disclaimer",
                      innerHTML: $setup.t('purchase_complete.mail_confirm.preferred_more_info_disclaimer', {
                                terms_link: $options.termsLink, interpolation: { escapeValue: false },
                            })
                    }, null, 8, _hoisted_20))
                  : _createCommentVNode("", true),
                _createElementVNode("p", {
                  class: "after-review-disclaimer modal-disclaimer",
                  innerHTML: $setup.t('purchase_complete.mail_confirm.disclaimer_copy', { supplyLength: $options.supplyLength, productDescription: $options.productDescription })
                }, null, 8, _hoisted_21)
              ])
            ])
          ], 64))
        : (!$options.mailRequired)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("h2", null, _toDisplayString($setup.t('purchase_complete.no_mail_confirm.headline_copy')), 1),
              _createVNode(_component_StarIcon),
              _createElementVNode("h3", {
                innerHTML: $setup.t('purchase_complete.no_mail_confirm.body_copy')
              }, null, 8, _hoisted_22),
              _createElementVNode("p", {
                class: "featured",
                innerHTML: $setup.t('purchase_complete.no_mail_confirm.disclaimer_copy', {
                        reward_amount: $options.totalAmount,
                        ...$options.prepaidCard, interpolation: { escapeValue: false },
                    })
              }, null, 8, _hoisted_23),
              ($props.baseReward)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createElementVNode("div", {
                      class: "subtotal-description",
                      innerHTML: $setup.t('purchase_complete.mail_confirm.base_reward_copy', { supplyLength: $options.supplyLength, productDescription: $options.productDescription })
                    }, null, 8, _hoisted_25),
                    _createElementVNode("div", _hoisted_26, [
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                      _createTextVNode(_toDisplayString($props.baseReward), 1),
                      _cache[11] || (_cache[11] = _createElementVNode("sup", { class: "double-dagger has-font-nunito-sans has-font-weight-regular" }, "‡", -1))
                    ])
                  ]))
                : _createCommentVNode("", true),
              ($options.bonusAmount > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("span", {
                        innerHTML: $setup.t('purchase_complete.mail_confirm.bonus_reward_copy')
                      }, null, 8, _hoisted_29),
                      _createVNode(_component_PreferredTooltip, { icon: "<img src='/public/img/question-circle.png' class='icon-question-circle' width='12' height='12'>" })
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _cache[12] || (_cache[12] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                      _createTextVNode(_toDisplayString($options.bonusAmount), 1),
                      _cache[13] || (_cache[13] = _createElementVNode("sup", null, " ", -1))
                    ])
                  ]))
                : _createCommentVNode("", true),
              ($options.promocodeAmount > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                    _createElementVNode("div", {
                      class: "subtotal-description",
                      innerHTML: $setup.t('purchase_complete.mail_confirm.promocode_reward_copy', {
                                promocodeAmount: $options.promocodeAmount,
                            })
                    }, null, 8, _hoisted_32),
                    _createElementVNode("div", _hoisted_33, [
                      _cache[14] || (_cache[14] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                      _createTextVNode(_toDisplayString($options.promocodeAmount), 1),
                      _cache[15] || (_cache[15] = _createElementVNode("sup", null, " ", -1))
                    ])
                  ]))
                : _createCommentVNode("", true),
              ($options.totalAmount)
                ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                    _createElementVNode("div", {
                      class: "subtotal-description",
                      innerHTML: $setup.t('purchase_complete.mail_confirm.total_reward_copy', { supplyLength: $options.supplyLength, productDescription: $options.productDescription })
                    }, null, 8, _hoisted_35),
                    _createElementVNode("div", _hoisted_36, [
                      _cache[16] || (_cache[16] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                      _createTextVNode(_toDisplayString($options.totalAmount), 1),
                      _cache[17] || (_cache[17] = _createElementVNode("sup", null, " ", -1))
                    ])
                  ]))
                : _createCommentVNode("", true),
              ($data.preferredFlag)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 4,
                    class: "preferred-more-info-disclaimer modal-disclaimer",
                    innerHTML: $setup.t('purchase_complete.mail_confirm.preferred_more_info_disclaimer', {
                        terms_link: $options.termsLink, interpolation: { escapeValue: false },
                    })
                  }, null, 8, _hoisted_37))
                : _createCommentVNode("", true),
              _createElementVNode("p", {
                class: "after-review-disclaimer modal-disclaimer modal-terms",
                innerHTML: $setup.t('purchase_complete.mail_confirm.dp_disclaimer_copy', { terms_link: $options.termsLink })
              }, null, 8, _hoisted_38),
              _createElementVNode("p", {
                class: "after-review-disclaimer modal-disclaimer",
                innerHTML: $setup.t('purchase_complete.mail_confirm.disclaimer_copy', { supplyLength: $options.supplyLength, productDescription: $options.productDescription })
              }, null, 8, _hoisted_39)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createElementVNode("h2", null, _toDisplayString($setup.t('purchase_complete.no_mail_confirm.headline_copy')), 1),
              _createElementVNode("p", {
                class: "featured",
                innerHTML: $setup.t('purchase_complete.no_mail_confirm.body_copy')
              }, null, 8, _hoisted_40),
              _createElementVNode("p", {
                class: "disclaimer",
                innerHTML: $setup.t('purchase_complete.no_mail_confirm.disclaimer_copy', {
                        reward_amount: $options.totalAmount,
                        ...$options.prepaidCard, interpolation: { escapeValue: false },
                    })
              }, null, 8, _hoisted_41)
            ], 64)),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        "data-e2e": "purchase-complete-modal-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "icon-close",
          alt: $setup.t('buttons:close')
        }, null, 8, _hoisted_42)
      ])
    ], 2)
  ], 512))
}