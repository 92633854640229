
    import axios from 'axios';
    import { mapActions, mapState, mapGetters } from 'vuex';
    import { useI18next } from '@composables/i18next';

    import PromoCodeForm from '@components/PromoCodeForm';
    import PreferredTooltip from '@components/tooltips/PreferredTooltip';
    import BaseModal from './BaseModal';
    import openModal, { CommentAdminModal } from './';

    export default {
        name: 'PurchaseDetailConfirmModal',

        components: {
            PromoCodeForm,
            PreferredTooltip,
        },

        extends: BaseModal,

        props: {
            submissionId: {
                type: String,
                default: '',
            },
        },

        setup () {
            const { t } = useI18next(['modal_content', 'global', 'reward_estimator', 'buttons', 'product_intake']);
            return { t };
        },

        data () {
            return {
                modalName: 'purchase-detail-confirm',
                promocodeBonusAmount: 0,
                result: {},
                supplies: {},
                dataLoaded: false,
                previousPromocodeApplied: {},
            };
        },

        computed: {
            ...mapState(['purchase', 'app', 'patient']),
            ...mapGetters({
                isAdminImpersonation: 'profile/isAdminImpersonation',
            }),

            bonusAmount () {
                let bonusAmount = 0;
                const bonusRewards = this.result.rewards.bonusRewards || {};

                if (bonusRewards && typeof bonusRewards === 'object') {
                    Object.values(bonusRewards).forEach((value) => {
                        bonusAmount += parseInt(value);
                    });
                }

                return bonusAmount;
            },

            brandCopy () {
                return this.t('product_intake:product_supply');
            },

            prepaidCard () {
                let copy = this.t('global:prepaid_card');
                copy = (typeof copy === 'string') ? { default: {} } : copy;
                const { card_format = {} } = (copy[this.app.subPhase]) || (copy.default);
                const { first_card_mention = '', second_card_mention = '', following_card_mentions = '' } = card_format;
                return { first_card_mention, second_card_mention, following_card_mentions };
            },

            formInputDisabled () {
                return modelProperty => this.edit == 'current' && !this.unResolvedFormValues.includes(`patient_${modelProperty}`);
            },

            termsLink () {
                const termsLink = `${this.app.siteURL}?locale=${this.app.locale}/#/rewardsTerms`;
                return termsLink;
            },

            totalAmount () {
                return parseInt(this.bonusAmount) + parseInt(this.result.rewards.baseReward) + parseInt(this.promocodeBonusAmount);
            },
        },

        async mounted () {
            this.result = await this.getRewardPreviewData();
            this.supplies = this.result.purchase_details.supplies;
            if (this.supplies.supplyLength) {
                this.dataLoaded = true;
            }

            // previousPromocodeApplied:
            // There will be occasions where the user has applied the promocode
            // but for whatever reason did not complete the rest of the form
            // and is now returning after a page refresh. In this case, we want to
            // check for any previously applied promo codes and show the appropriate
            // messaging. That's what this variable/prop does.
            this.previousPromocodeApplied = this.result.rewards?.promocodeRewards || {};
        },

        methods: {
            ...mapActions({
                makeCall: 'purchase/makeCall',
            }),
            getFirstTimeWearerCopy () {
                let firstTimeWearerCopy = '';
                if (this.result && this.result.purchase_details.patient_type === 'new_acuvue') {
                    firstTimeWearerCopy =  this.t('reward_results.first_time');
                }
                return firstTimeWearerCopy;
            },

            getSupplyCopy () {
                return this.t(`supply_amount.${this.result.purchase_details?.supplies?.supplyLength}`);
            },

            async getRewardPreviewData () {
                const result = await axios.get(`/api/purchase-submissions/search/by-id?purchase_submission_id=${this.patient.purchase_submission_id}`);
                return result.data.result.purchaseSubmission;
            },

            isPreferredBonusEligible () {
                return parseInt(this.result.rewards.bonusRewards.preferredPractice) > 0;
            },

            onPromocodeApplied (event) {
                this.promocodeBonusAmount = parseInt(event?.promocodeBonusAmount) || 0;
            },
            goToReceiptUpload () {
                this.closeModal();
                if (this.isAdminImpersonation) {
                    openModal(CommentAdminModal, {
                        submissionId: this.patient.purchase_submission_id,
                    });

                    // window.location = `${this.app.siteURL}admin#/purchase-validation/searchBy/entryId/${this.submissionId}`;
                }
                else {
                    this.$router.push('receipt_upload');
                }
            },
            cancelAction () {
                if (this.promocodeBonusAmount != 0) {
                    this.$refs.PromoCodeForm.deletePromocode();
                }
                this.closeModal();
            },
        },
    };
