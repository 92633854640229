import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal address-verification-modal",
  role: "dialog"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "disclaimer" }
const _hoisted_5 = ["alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: "modal-overlay",
    onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString($setup.t('headline')), 1),
      _createElementVNode("div", {
        innerHTML: $setup.t('body')
      }, null, 8, _hoisted_2),
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers((...args) => ($options.submit && $options.submit(...args)), ["prevent"]))
      }, [
        _createElementVNode("fieldset", null, [
          _createElementVNode("label", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.choice) = $event)),
              name: "address-verification",
              type: "radio",
              value: "recommended"
            }, null, 512), [
              [_vModelRadio, $data.choice]
            ]),
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString($setup.t('use_recommended')) + " ", 1),
              _createElementVNode("address", null, [
                _createTextVNode(_toDisplayString($props.patientForm.address1) + " " + _toDisplayString($props.patientForm.address2) + " ", 1),
                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString($props.suggestedCity) + ", " + _toDisplayString($props.suggestedState) + " " + _toDisplayString($props.patientForm.postal_code), 1)
              ])
            ])
          ]),
          _createElementVNode("label", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.choice) = $event)),
              type: "radio",
              name: "address-verification",
              value: "back"
            }, null, 512), [
              [_vModelRadio, $data.choice]
            ]),
            _createElementVNode("div", null, _toDisplayString($setup.t('go_back')), 1)
          ])
        ]),
        _createElementVNode("button", {
          class: "with-right-arrow",
          disabled: $options.disabled
        }, _toDisplayString($setup.t('continue')), 9, _hoisted_3)
      ], 32),
      _createElementVNode("p", _hoisted_4, _toDisplayString($setup.t('disclaimer')), 1),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "icon-close",
          alt: $setup.t('buttons:close')
        }, null, 8, _hoisted_5)
      ])
    ])
  ], 512))
}