import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal questions-modal",
  role: "dialog"
}
const _hoisted_2 = { class: "questions-header" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { class: "questions-modal-container" }
const _hoisted_6 = { class: "callout" }
const _hoisted_7 = { class: "questions-header" }
const _hoisted_8 = ["textContent"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = {
  ref: "rewards_online",
  class: "chat-buttons"
}
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = { ref: "rewards_offline" }
const _hoisted_14 = ["textContent"]
const _hoisted_15 = ["textContent"]
const _hoisted_16 = ["textContent"]
const _hoisted_17 = ["textContent"]
const _hoisted_18 = { class: "field base-search" }
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = ["value"]
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "callout" }
const _hoisted_23 = { class: "questions-header" }
const _hoisted_24 = ["textContent"]
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = ["alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PhoneIcon = _resolveComponent("PhoneIcon")
  const _component_OutgoingAffiliate = _resolveComponent("OutgoingAffiliate")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[7] || (_cache[7] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", {
          textContent: _toDisplayString($setup.t('questions_modal.intro.header', { name: _ctx.firstName }))
        }, null, 8, _hoisted_3),
        _createElementVNode("p", {
          textContent: _toDisplayString($setup.t('questions_modal.intro.body'))
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h3", {
              textContent: _toDisplayString($setup.t('questions_modal.rewards.header'))
            }, null, 8, _hoisted_8),
            _createElementVNode("p", {
              textContent: _toDisplayString($setup.t('questions_modal.rewards.body'))
            }, null, 8, _hoisted_9)
          ]),
          ($props.showRewardsChat)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _withDirectives(_createElementVNode("div", _hoisted_10, [
                  _createElementVNode("a", {
                    href: "javascript://Chat",
                    class: "button",
                    onClick: _cache[0] || (_cache[0] = $event => ($options.openLiveAgentChat($props.rewardsChatId))),
                    textContent: _toDisplayString($setup.t('questions_modal.rewards.chat.cta'))
                  }, null, 8, _hoisted_11),
                  _createElementVNode("a", {
                    href: "#/faq?goToForm=1",
                    class: "button alt",
                    onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"])),
                    textContent: _toDisplayString($setup.t('questions_modal.rewards.message.cta'))
                  }, null, 8, _hoisted_12)
                ], 512), [
                  [_vShow, $data.showRewardsOnline]
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_13, [
                  _createElementVNode("p", null, [
                    _createElementVNode("strong", {
                      textContent: _toDisplayString($setup.t('questions_modal.no_agents'))
                    }, null, 8, _hoisted_14)
                  ]),
                  _createElementVNode("a", {
                    href: "#/faq?goToForm=1",
                    class: "button alt",
                    onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"])),
                    textContent: _toDisplayString($setup.t('questions_modal.rewards.message.cta'))
                  }, null, 8, _hoisted_15)
                ], 512), [
                  [_vShow, $data.showRewardsOffline]
                ])
              ], 64))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: "#/faq?goToForm=1",
                class: "button alt",
                onClick: _cache[3] || (_cache[3] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"])),
                textContent: _toDisplayString($setup.t('questions_modal.rewards.message.cta'))
              }, null, 8, _hoisted_16)),
          _createElementVNode("form", {
            class: "search-form",
            onSubmit: _cache[5] || (_cache[5] = _withModifiers((...args) => ($options.searchFAQ && $options.searchFAQ(...args)), ["prevent"]))
          }, [
            _createElementVNode("label", {
              for: "search_form",
              textContent: _toDisplayString($setup.t('questions_modal.rewards.search.label'))
            }, null, 8, _hoisted_17),
            _createElementVNode("div", _hoisted_18, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.search) = $event)),
                type: "text",
                placeholder: $setup.t('questions_modal.rewards.search.placeholder')
              }, null, 8, _hoisted_19), [
                [_vModelText, $data.search]
              ]),
              _createElementVNode("button", {
                class: "search-button",
                type: "submit",
                value: $setup.t('questions_modal.rewards.search.cta')
              }, null, 8, _hoisted_20)
            ])
          ], 32),
          ($props.showRewardsChat)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(_component_PhoneIcon, { class: "small-only" }),
                _createElementVNode("p", {
                  innerHTML: $setup.t('questions_modal.rewards.call_us')
                }, null, 8, _hoisted_21)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("h3", {
              class: "turquoise",
              textContent: _toDisplayString($setup.t('questions_modal.contact_lenses.header'))
            }, null, 8, _hoisted_24),
            _createElementVNode("p", {
              innerHTML: $setup.t('questions_modal.contact_lenses.body')
            }, null, 8, _hoisted_25)
          ]),
          _createElementVNode("div", {
            class: "topic-container",
            innerHTML: $setup.t('questions_modal.contact_lenses.topics')
          }, null, 8, _hoisted_26),
          _createVNode(_component_OutgoingAffiliate, {
            "affiliate-id": $setup.t('questions_modal.contact_lenses.faq.affiliate'),
            target: "_blank",
            class: "button turquoise with-right-arrow"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", {
                class: "button lensassist",
                innerHTML: $setup.t('questions_modal.contact_lenses.faq.cta')
              }, null, 8, _hoisted_27)
            ]),
            _: 1
          }, 8, ["affiliate-id"])
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        tabindex: "0",
        onClick: _cache[6] || (_cache[6] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "icon-close",
          alt: $setup.t('buttons:close')
        }, null, 8, _hoisted_28)
      ])
    ])
  ]))
}