
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'AddressVerificationModal',

        extends: BaseModal,

        props: {
            suggestedCity: {
                required: true,
                type: String,
            },
            suggestedState: {
                required: true,
                type: String,
            },
            patientForm: {
                required: true,
                type: Object,
            },
        },

        setup () {
            const { t } = useI18next(['address_verification_modal']);
            return { t };
        },

        data () {
            return {
                modalName: 'address-verification',
                choice: null,
            };
        },

        computed: {
            disabled () {
                return !this.choice;
            },
        },

        methods: {
            submit () {
                this.closeModal(this.choice);
            },
        },
    };
