import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "reward-estimator" }
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "callout" }
const _hoisted_5 = { class: "base-subtotal subtotal-container base-reward-subtotal" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "subtotal-amount" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "subtotal-container bonus-subtotal"
}
const _hoisted_10 = { class: "bonus-subtotal subtotal-description" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "subtotal-amount" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "disclaimer-container" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")
  const _component_Validator = _resolveComponent("Validator")
  const _component_PreferredTooltip = _resolveComponent("PreferredTooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h2", {
        innerHTML: $setup.t('headline_copy')
      }, null, 8, _hoisted_3),
      _createElementVNode("form", {
        onSubmit: _cache[3] || (_cache[3] = _withModifiers((...args) => ($options.getRewardEstimate && $options.getRewardEstimate(...args)), ["prevent"]))
      }, [
        _createVNode(_component_Validator, {
          ref: "validator-reward-estimator",
          guards: $data.rewardEstimatorValidator,
          data: $options.rewardEstimatorData
        }, {
          default: _withCtx(({
                        isError,
                    }) => [
            ($options.patientOptions.length > 0)
              ? (_openBlock(), _createBlock(_component_BaseSelect, {
                  key: 0,
                  modelValue: $data.patient_name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.patient_name) = $event)),
                  name: "patient_name",
                  label: $setup.t('reward_estimator:patient_name'),
                  "select-one": $setup.t('select_one'),
                  options: $options.patientOptions
                }, null, 8, ["modelValue", "label", "select-one", "options"]))
              : _createCommentVNode("", true),
            ($options.brandOptions.length > 0)
              ? (_openBlock(), _createBlock(_component_BaseSelect, {
                  key: 1,
                  modelValue: $data.brand,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.brand) = $event)),
                  name: "brand",
                  label: $setup.t('reward_estimator:brand'),
                  "select-one": $setup.t('select_one'),
                  "is-error": isError('brand'),
                  options: $options.brandOptions
                }, null, 8, ["modelValue", "label", "select-one", "is-error", "options"]))
              : _createCommentVNode("", true),
            ($options.monthlyAmountOptions.length > 0)
              ? (_openBlock(), _createBlock(_component_BaseSelect, {
                  key: 2,
                  modelValue: $data.monthly_amount,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.monthly_amount) = $event)),
                  name: "monthly_amount",
                  label: $setup.t('reward_estimator:monthly_amount'),
                  "select-one": $setup.t('select_one'),
                  "is-error": isError('monthly_amount'),
                  options: $options.monthlyAmountOptions
                }, null, 8, ["modelValue", "label", "select-one", "is-error", "options"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["guards", "data"])
      ], 32)
    ]),
    ($data.result)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "subtotal-description",
                innerHTML: $setup.t('reward_results.body_copy', {
                            first_time: $options.firstTimeWearer,
                            brand_name: $options.supplyCopy[$data.brand],
                            purchase_amount: $options.supplyOptionCopy[$data.monthly_amount],
                        })
              }, null, 8, _hoisted_6),
              _createElementVNode("div", _hoisted_7, [
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                _createTextVNode(_toDisplayString($data.result.awardAmount), 1),
                _cache[6] || (_cache[6] = _createElementVNode("sup", { class: "double-dagger has-font-nunito-sans has-font-weight-regular" }, "‡", -1)),
                _createElementVNode("p", {
                  class: "subtotal-disclaimer-indication",
                  innerHTML: $setup.t('reward_results.disclaimer_indication')
                }, null, 8, _hoisted_8)
              ])
            ]),
            ($options.bonusAmount > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", {
                      innerHTML: $setup.t('reward_results.bonus_copy', { bonus_amount: $options.bonusAmount })
                    }, null, 8, _hoisted_11),
                    ($options.bonusAmount > 0)
                      ? (_openBlock(), _createBlock(_component_PreferredTooltip, {
                          key: 0,
                          icon: "<img src='/public/img/question-circle.png' class='icon-question-circle' width='24' height='24'>"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                    _createTextVNode(_toDisplayString($options.bonusAmount), 1),
                    _cache[8] || (_cache[8] = _createElementVNode("sup", null, " ", -1))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.ui.pageName === 'dashboard')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", {
                  class: "instruction-text",
                  innerHTML: $setup.t('reward_results.button_body_copy')
                }, null, 8, _hoisted_13),
                ($options.showGetStartedCopy)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      innerHTML: $setup.t('reward_results.get_started_body_copy')
                    }, null, 8, _hoisted_14))
                  : _createCommentVNode("", true),
                _createElementVNode("button", {
                  class: "btn reward-submit with-right-arrow",
                  onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.$emit('close')), ["prevent"]))
                }, _toDisplayString($setup.t('buttons:get_started')), 1)
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_15, [
      ($data.brand != '')
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: "purchase-disclaimer",
            innerHTML: $setup.t('disclaimer.purchase_copy', { terms_link: $options.termsLink, ...$options.prepaidCard, interpolation: { escapeValue: false } })
          }, null, 8, _hoisted_16))
        : _createCommentVNode("", true),
      ($data.monthly_amount != '')
        ? (_openBlock(), _createElementBlock("p", {
            key: 1,
            innerHTML: $setup.t('disclaimer.card_copy', { terms_link: $options.termsLink, ...$options.prepaidCard, interpolation: { escapeValue: false } })
          }, null, 8, _hoisted_17))
        : _createCommentVNode("", true),
      ($data.monthly_amount === '3' || $data.monthly_amount === '6')
        ? (_openBlock(), _createElementBlock("p", {
            key: 2,
            innerHTML: $setup.t('disclaimer.3_6_month')
          }, null, 8, _hoisted_18))
        : _createCommentVNode("", true),
      ($data.monthly_amount === '9')
        ? (_openBlock(), _createElementBlock("p", {
            key: 3,
            innerHTML: $setup.t('disclaimer.9_month')
          }, null, 8, _hoisted_19))
        : _createCommentVNode("", true),
      ($options.isTransitions)
        ? (_openBlock(), _createElementBlock("p", {
            key: 4,
            innerHTML: $setup.t('disclaimer.transitions')
          }, null, 8, _hoisted_20))
        : _createCommentVNode("", true)
    ])
  ]))
}