import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal approval-confirmation-modal",
  role: "dialog"
}
const _hoisted_2 = ["alt"]
const _hoisted_3 = { class: "express-rewards-body-copy" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "earning-details-container" }
const _hoisted_8 = { class: "base-subtotal subtotal-container base-reward-subtotal" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "subtotal-amount has-color-dark-blue" }
const _hoisted_12 = {
  key: 0,
  class: "bonus-subtotal subtotal-container bonus-reward-subtotal"
}
const _hoisted_13 = { class: "subtotal-description" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "subtotal-amount has-color-dark-blue" }
const _hoisted_16 = {
  key: 1,
  class: "bonus-subtotal subtotal-container bonus-reward-subtotal"
}
const _hoisted_17 = { class: "subtotal-description" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "subtotal-amount has-color-dark-blue" }
const _hoisted_20 = {
  key: 2,
  class: "bonus-subtotal subtotal-container bonus-reward-subtotal"
}
const _hoisted_21 = { class: "subtotal-description" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { class: "subtotal-amount has-color-dark-blue" }
const _hoisted_24 = { class: "total subtotal-container total-reward-subtotal" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = { class: "subtotal-amount has-color-magenta" }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_StarIcon = _resolveComponent("StarIcon")
  const _component_PreferredTooltip = _resolveComponent("PreferredTooltip")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "icon-close",
          alt: $setup.t('buttons:close')
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("h2", null, _toDisplayString($setup.t('headline_copy')), 1),
      _createVNode(_component_StarIcon),
      _createElementVNode("p", _hoisted_3, [
        _createElementVNode("strong", null, [
          _createElementVNode("span", {
            innerHTML: $setup.t('total_reward_copy', { reward: $options.earnedAmount })
          }, null, 8, _hoisted_4)
        ])
      ]),
      _createElementVNode("p", {
        innerHTML: $setup.t('body_copy_fulfillment_info')
      }, null, 8, _hoisted_5),
      ($props.hasRemainingExpressRewardsClaims)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            innerHTML: $setup.t('more_express_rewards')
          }, null, 8, _hoisted_6))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          ($props.supplies.brand_l == $props.supplies.brand_r)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "subtotal-description",
                innerHTML: $setup.t('purchase_complete.mail_confirm.base_reward_copy', {
                            supplyLength: $options.getSupplyCopy(),
                            productDescription: $options.brandCopy[$props.supplies.brand_l || $props.supplies.brand_r],
                        })
              }, null, 8, _hoisted_9))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "subtotal-description",
                innerHTML: $setup.t('purchase_complete.mail_confirm.base_reward_copy_both', {
                            supplyLength: $options.getSupplyCopy(),
                            productDescriptionLeft: $options.brandCopy[$props.supplies.brand_l],
                            productDescriptionRight: $options.brandCopy[$props.supplies.brand_r],
                        })
              }, null, 8, _hoisted_10)),
          _createElementVNode("div", _hoisted_11, [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
            _createTextVNode(_toDisplayString($props.baseReward || $props.completeRewards?.baseReward), 1),
            _cache[3] || (_cache[3] = _createElementVNode("sup", null, " ‡", -1))
          ])
        ]),
        ($props.completeRewards?.trueUpReward > 0 || $props.trueUpReward > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", {
                  innerHTML: $setup.t('true_up_bonus', { trueUpReward: $props.completeRewards?.trueUpReward || $props.trueUpReward })
                }, null, 8, _hoisted_14)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                _createTextVNode(_toDisplayString($props.completeRewards?.trueUpReward || $props.trueUpReward), 1),
                _cache[5] || (_cache[5] = _createElementVNode("sup", null, " ", -1))
              ])
            ]))
          : _createCommentVNode("", true),
        ($props.completeRewards?.bonusReward > 0 || $props.bonusReward > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("span", {
                  innerHTML: $setup.t('preferred_practice_bonus', { bonusReward: $props.completeRewards?.bonusReward || $props.bonusReward })
                }, null, 8, _hoisted_18),
                _createVNode(_component_PreferredTooltip, { icon: "<img src='/public/img/question-circle.png' class='icon-question-circle' width='12' height='12'>" })
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                _createTextVNode(_toDisplayString($props.completeRewards?.bonusReward || $props.bonusReward), 1),
                _cache[7] || (_cache[7] = _createElementVNode("sup", null, " ", -1))
              ])
            ]))
          : _createCommentVNode("", true),
        ($props.completeRewards?.promocodeReward > 0 || $props.promocodeReward > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("span", {
                  innerHTML: $setup.t('promocode_bonus', { promocodeReward: $props.completeRewards?.promocodeReward || $props.promocodeReward })
                }, null, 8, _hoisted_22)
              ]),
              _createElementVNode("div", _hoisted_23, [
                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
                _createTextVNode(_toDisplayString($props.completeRewards?.promocodeReward || $props.promocodeReward), 1),
                _cache[9] || (_cache[9] = _createElementVNode("sup", null, " ", -1))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", {
            class: "subtotal-description",
            innerHTML: $setup.t('purchase_complete.mail_confirm.total_reward_copy')
          }, null, 8, _hoisted_25),
          _createElementVNode("div", _hoisted_26, [
            _cache[10] || (_cache[10] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
            _createTextVNode(_toDisplayString($options.totalAmount), 1),
            _cache[11] || (_cache[11] = _createElementVNode("sup", null, " ", -1))
          ])
        ])
      ]),
      _createElementVNode("p", {
        class: "disclaimer",
        innerHTML: $setup.t('purchase_complete.mail_confirm.disclaimer_copy')
      }, null, 8, _hoisted_27),
      _createElementVNode("p", {
        class: "disclaimer",
        innerHTML: $setup.t('disclaimer.purchase_copy_old', { ...$options.prepaidCard, interpolation: { escapeValue: false } })
      }, null, 8, _hoisted_28)
    ])
  ]))
}