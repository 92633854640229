/* These are turned into rocketship-validation objects.
 * Information on that is located in the readme for that.
 * https://github.com/rocketship-core/validator
 */
const
    moment = require('moment-timezone'),
    range = require('lodash/range'),
    isEmail = require('validator/lib/isEmail'),
    isDate = require('validator/lib/isDate'),
    isLength = require('validator/lib/isLength'),
    isLowercase = require('validator/lib/isLowercase'),
    isISO8601 = require('validator/lib/isISO8601'),
    isNumber = /^-?[0-9][0-9,.]+$/,
    isSupplyInteger = /^[0-9]{1,2}$/,
    { isFilled } = require('rocketship-validator'),
    config = require('rocketship-config'),
    { maxFields, displayCAConfirm } = {"_public":true,"maxFields":3,"displayCAConfirm":true},
    isZip = /^\d{5}(?:-\d{4})?$/,
    isZip5Digit = /^\d{5}$/,
    noSpecialCharsNumsInName = /^[A-Za-zÀ-ž .,\/;:>&'"!‘“’”()-]+$/, // eslint-disable-line no-useless-escape
    noSpecialCharsInAddress = /^[A-Za-zÀ-ž0-9 .,\/()#&'‘’:>!;-]+$/, // eslint-disable-line no-useless-escape
    noSpecialCharsInState = /^[A-Za-zÀ-ž0-9]+$/,
    noEmoji = /^[\u{000000}-\u{00FFFF}]*$/u,  // eslint-disable-line no-control-regex
    noEmail = /^((?!@).)*$/,
    isUrl = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
    isNineNineNine = /^999$/,
    isSKU = /^(\d+){11}$/,
    isJiraId = /^(?=.{1,12}$)[A-Z]+-\d+$/,
    emailValidator = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
    phoneValidator = /(^[2-9]{1}[0-9]{2}(\))?[-.]?[2-9]{1}[0-9]{2}[-.]?[0-9]{4}$)|(^$)/,
    rewardConfValidator = /^[a-zA-Z0-9]{14}$|^[a-zA-Z0-9-]{16}$/,
    oneAlphaCharInAddress = /[a-zA-Z]/,
    isAlphanumericHyphen = /[a-zA-Z0-9-]/,
    isAlphanumericWithUnderscore = /^[\w]+$/,
    oneNumericCharInAddress = /\d/,
    alphaNumericTenChar = /^\w{10}$/,
    validPrograms = ["preferredpractice","pilot","trial","2020_q1","2020_q2","2020_q3","2020_q4","2021_q1","2021_q2","2021_q3","2021_q4","2022_q1","2022_q2","2022_q3","2022_q4","2023_q1","2023_q2","2023_q3","2023_q4","2024_q1","2024_q2","2024_q3","2024_q4","2025_q1","2025_q2","2025_q3","2025_q4","checkout_shop","oasys_max_pilot"];
moment.tz.setDefault('Etc/UTC');

module.exports = {
    login: {
        email: {
            required: true,
            external: [isEmail],
            noEmoji,
        },
    },
    register: {
        first_name: {
            required: true,
            noEmoji,
            noEmail,
        },
        last_name: {
            required: true,
            noEmoji,
            noEmail,
        },
        email: {
            required: true,
            external: [isEmail],
            noEmoji,
        },
        birthday: {
            required: true,
            noEmoji,
        },
    },
    viral: {
        to_email1: {
            required: true,
            external: [isEmail],
            noEmoji,
            // These validators run on the first field, but are smart enough to check
            // for errors in all TO email addresses.
            // Note: `referringSelf` may only be validated client-side if `email` is a
            // public field in the profile config, and it's not by default.
            referringSelf (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj);

                const referredSelf = toEmailFields.find(([key, email]) => email === obj.selfEmail);

                if (referredSelf) {
                    const [selfKey] = referredSelf;
                    validation.addError(selfKey, 'REFERRED_SELF');
                    return false;
                }

                return true;
            },
            duplicateReferral (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj),
                    seen = {};

                const duplicate = toEmailFields.find(([key, email]) => {
                    if (email in seen) return true;
                    seen[email] = true;
                    return false;
                });

                if (duplicate) {
                    const [duplicateKey] = duplicate;
                    validation.addError(duplicateKey, 'DUPLICATE');
                    return false;
                }

                return true;
            },
        },
        to_name1: {
            required: true,
            noEmoji,
        },
    },
    faqContact: {
        first_name: {
            required: true,
        },
        email: {
            required: true,
            external: [isEmail],
        },
        question: {
            required: true,
        },
        issue_type: {
            required: true,
        },
        reward_code: {
            required: false,
            rewardConfValidator,
        },
    },
    faqECPNotListed: {
        first_name: {
            required: true,
        },
        email: {
            required: true,
            external: [isEmail],
        },
        question: {
            required: true,
        },
        issue_type: {
            required: true,
        },
        reward_code: {
            required: false,
            rewardConfValidator,
        },
        ecp_name: {
            required: true,
            noEmoji,
            noSpecialCharsInAddress,
        },
        ecp_address: {
            required: true,
            noEmoji,
            noSpecialCharsInAddress,
            oneAlphaCharInAddress,
            oneNumericCharInAddress,
        },
        ecp_city: {
            required: true,
            noEmoji,
            noSpecialCharsInAddress,
        },
        ecp_state: {
            required: true,
            noSpecialCharsInState,
        },
        ecp_zip: {
            required: true,
            isZip5Digit,
            noEmoji,
        },
    },
    rewardEstimationBody: {
        email: {
            required: true,
            external: [isEmail],
        },
        sap: {
            required: true,
        },
        unit_of_measure: {
            required: true,
            isIn: ['months', ''],
        },
        patient_name: {
            required: true,
            noEmoji,
            noEmail,
            noSpecialCharsNumsInName,
        },
        products: {
            required: true,
        },
    },
    productArray: {
        brand: {
            required: true,
        },
        supply: {
            required: true,
            isSupplyAValidNumber,
        },
    },
    patientCheckout: {
        postal_code: {
            required: true,
            isZip,
            noEmoji,
        },
    },
    patientInfo: {
        name: {
            required: true,
            noEmoji,
            noSpecialCharsNumsInName,
        },
        relation_to_profile: {
            required: true,
        },
        address1: {
            required: true,
            noEmoji,
            noSpecialCharsInAddress,
            oneAlphaCharInAddress,
            oneNumericCharInAddress,
        },
        address2: {
            required: false,
            noEmoji,
            noSpecialCharsInAddress,
        },
        city: {
            required: true,
            noEmoji,
            noSpecialCharsInAddress,
        },
        state: {
            required: true,
            noSpecialCharsInState,
        },
        postal_code: {
            required: true,
            isZip5Digit,
            noEmoji,
        },
        country: {
            required: true,
            noEmoji,
        },
    },
    patientStudentStatus: {
        student: {
            required: true,
            isIn: ['yes', 'no', 'prefer_not_to_answer'],
        },
    },
    updatePatientInfo: {
        id: {
            required: true,
        },
    },
    checkoutShopPatientInfo: {
        name: {
            required: true,
            noEmoji,
            noEmail,
            noSpecialCharsNumsInName,
        },
    },
    eyeCareProfessionalSearchByPostalCodeAndAccountName: {
        eye_care_professional_postal_code: {
            required: true,
            isZip5Digit,
            noEmoji,
        },
        eye_care_professional_account_name: {
            required: true,
            noEmoji,
        },
        country: {
            required: true,
            noEmoji,
        },
    },
    eyeCareProfessionalSearchByRadius: {
        eye_care_professional_postal_code: {
            required: true,
            noEmoji,
            isZip5Digit,
        },
        radius: {
            required: true,
        },
    },
    eyeCareProfessionalSearchByPostalCode: {
        eye_care_professional_postal_code: {
            required: true,
            noEmoji,
            isZip5Digit,
        },
    },
    eyeCareProfessional: {
        sap: {
            required: true,
        },
    },
    eyeCareProfessionalCreateUpdate: {
        sap: {
            required: true,
        },
        account_name: {
            required: true,
            noEmoji,
        },
        latitude: {
            required: true,
        },
        longitude: {
            required: true,
        },
        address1: {
            required: true,
            noEmoji,
        },
        address2: {
            required: false,
            noEmoji,
        },
        city: {
            required: true,
            noEmoji,
        },
        state: {
            required: true,
        },
        postal_code: {
            required: true,
            isZip,
            noEmoji,
        },
        checkout_shop_start: {
            required: false,
            isValidDateFormat,
        },
        status: {
            required: false,
            isValidECPStatus,
        },
        territory_code: {
            required: false,
            external: [isLength, { min: 0, max: 50 }],
        },
        unaffiliated: {
            required: false,
            isValidUnaffiliatedStatus,
        },
    },
    bonusReconcileUpload: {
        bonus_type: {
            required: true,
            isIn: ['preferredPractice', 'checkoutShop'],
        },
        reward_value: {
            required: true,
        },
    },
    eyeCareProfessionalUploadDefault: {
        sap: {
            required: true,
        },
        account_name: {
            required: false,
            noEmoji,
        },
        latitude: {
            required: false,
        },
        longitude: {
            required: false,
        },
        address1: {
            required: false,
            noEmoji,
        },
        address2: {
            required: false,
            noEmoji,
        },
        city: {
            required: false,
            noEmoji,
        },
        state: {
            required: false,
        },
        postal_code: {
            required: false,
            isZip,
            noEmoji,
        },
        checkout_shop_start: {
            required: false,
            isValidDateFormat,
        },
        status: {
            required: false,
            isValidECPStatus,
        },
        territory_code: {
            required: false,
            external: [isLength, { min: 0, max: 50 }],
        },
        unaffiliated: {
            required: false,
            isValidUnaffiliatedStatus,
        },
    },
    eyeCareProfessionalUploadPeriod: {
        start_date: {
            required: false,
            isValidDateFormat,
        },
        end_date: {
            required: false,
            isValidDateFormat,
        },
        period_name: {
            required: false,
            external: [isLowercase],
            isValidECPPeriod,
        },
    },
    eyeCareProfessionalEligibilityPeriod: {
        affiliation_start_date: {
            required: true,
            isValidDateFormat,
        },
        affiliation_end_date: {
            required: true,
            isValidDateFormat,
        },
        eligibility_period_name: {
            required: true,
            external: [isLowercase],
        },
    },
    eyeCareProfessionalExpressPeriod: {
        express_start_date: {
            required: true,
            isValidDateFormat,
        },
        express_end_date: {
            required: true,
            isValidDateFormat,
        },
        express_period_name: {
            required: true,
            external: [isLowercase],
        },
    },
    eyeCareProfessionalUploadLocation: {
        latitude: {
            required: true,
            isNumber,
        },
        longitude: {
            required: true,
            isNumber,
        },
        address1: {
            required: true,
            noEmoji,
        },
        address2: {
            required: false,
            noEmoji,
        },
        city: {
            required: true,
            noEmoji,
        },
        state: {
            required: true,
            external: [isLength, { min: 2, max: 2 }],
        },
        postal_code: {
            required: true,
            isZip,
            noEmoji,
        },
        country: {
            required: true,
            isIn: ['US', 'CA'],
        },
    },
    eyeCareProfessionalNotFound: {
        sap: {
            required: true,
            isNineNineNine,
        },
        first_name: {
            required: true,
        },
        last_name: {
            required: true,
        },
    },
    invoiceFileUpload: {
        entry_id: {
            required: true,
        },
        invoice_date: {
            required: true,
            isValidDateFormat,
        },
        value: {
            required: true,
        },
        fulfillment_type: {
            required: true,
        },
        jira_id: {
            required: true,
        },
    },
    submitSupplies: {
        purchase_submission_id: {
            required: true,
        },
        product_image_url: {
            required: true,
            isUrl,
        },
        patientId: {
            required: true,
        },
    },
    suppliesPurchased: {
        brand_l: {
            required: true,
        },
        lenses_per_box_l: {
            required: true,
            isSupplyInteger,
        },
        boxes_l: {
            required: true,
            isSupplyInteger,
        },
        brand_r: {
            required: true,
        },
        lenses_per_box_r: {
            required: true,
            isSupplyInteger,
        },
        boxes_r: {
            required: true,
            isSupplyInteger,
        },
    },
    checkoutSuppliesPurchased: {
        brand_l: {
            required: true,
        },
        lenses_per_box_l: {
            required: true,
            isSupplyInteger,
        },
        boxes_l: {
            required: true,
            isSupplyInteger,
        },
        brand_r: {
            required: true,
        },
        lenses_per_box_r: {
            required: true,
            isSupplyInteger,
        },
        boxes_r: {
            required: true,
            isSupplyInteger,
        },
    },
    incompletePurchaseSubmission: {
        batch_id: {
            required: true,
        },
        incomplete_reason: {
            required: true,
        },
        comment: {
            required: true,
        },
    },
    receiptImage: {
        receipt_image_url: {
            required: true,
            isUrl,
        },
        purchase_submission_id: {
            required: true,
        },
    },
    purchaseSubmissionGetById: {
        purchase_submission_id: {
            required: true,
        },
    },
    confirmPurchaseSubmission: {
        purchase_submission_id: {
            required: true,
        },
    },
    purchaseDate: {
        purchase_date: {
            required: true,
            external: [
                isDate,
                {
                    format: 'YYYY-MM-DD',
                    strictMode: true,
                    delimiters: ['-'],
                },
            ],
        },
        sap: {
            required: true,
        },
        patient_id: {
            required: true,
        },
    },
    checkoutRegister: {
        first_name: {
            required: true,
            noEmoji,
            noEmail,
        },
        last_name: {
            required: true,
            noEmoji,
            noEmail,
        },
        email: {
            required: true,
            external: [isEmail],
            noEmoji,
        },
        birthday: {
            required: true,
            noEmoji,
            external: [
                isDate,
                {
                    format: 'YYYY-MM-DD',
                    strictMode: true,
                    delimiters: ['-'],
                },
            ],
        },
        primary_opt_in: {
            required: false,
        },
    },
    rewardEstimator: {
        brand: {
            required: true,
        },
        monthly_amount: {
            required: true,
        },
    },
    purchaseSKU: {
        SKU: {
            required: true,
            isSKU,
        },
    },
    checkoutPurchaseSubmission: {
        supplies: {
            required: true,
        },
        sap: {
            required: true,
        },
        order_number: {
            required: true,
        },
        purchase_date: {
            required: true,
            external: [
                isDate,
                {
                    format: 'YYYY-MM-DD',
                    strictMode: true,
                    delimiters: ['-'],
                },
            ],
        },
    },
    orderNumber: {
        order_number: {
            required: true,
        },
    },
    sms: {
        phone_number: {
            required: true,
            phoneValidator,
        },
    },
    authCode: {
        code: {
            required: true,
        },
    },
    impersonationComment: {
        additionalComments: {
            required: true,
        },
    },
    limitRemaining: {
        patient_id: {
            required: true,
        },
        purchase_date: {
            required: true,
            external: [
                isDate,
                {
                    format: 'YYYY-MM-DD',
                    strictMode: true,
                    delimiters: ['-'],
                },
            ],
        },
    },
    startSubmission: {
        patient_id: {
            required: true,
        },
        sap: {
            required: true,
            isUserGenerated,
        },
    },
    checkoutConfirm: {
        purchase_submission_id: {
            required: true,
        },
        profile_id: {
            required: true,
        },
    },
    checkoutPhotoUpload: {
        purchase_submission_id: {
            required: true,
        },
        product_image_url: {
            required: true,
        },
    },
    checkoutConfirmPatient: {
        // used by app/lib/routes/api/checkout-shop/confirm
        name: {
            required: true,
            noEmoji,
            noSpecialCharsNumsInName,
        },
        id: {
            required: true,
        },
        address1: {
            required: true,
            noEmoji,
            oneAlphaCharInAddress,
            oneNumericCharInAddress,
        },
        address2: {
            required: false,
            noEmoji,
        },
        city: {
            required: true,
            noEmoji,
        },
        state: {
            required: true,
        },
        postal_code: {
            required: true,
            isZip,
            noEmoji,
        },
        relation_to_profile: {
            required: true,
            isRelationToProfileSelected,
        },
    },
    validFlexSubmission: {
        patientType: {
            required: true,
        },
    },
    requiredPatientInfo: {
        // used by app/public/js/views/CheckoutShopPatientInfoPage.vue
        name: {
            required: true,
            noEmoji,
            noSpecialCharsNumsInName,
        },
        relation_to_profile: {
            required: true,
            isRelationToProfileSelected,
        },
        address1: {
            required: true,
            noEmoji,
            noSpecialCharsInAddress,
            oneAlphaCharInAddress,
            oneNumericCharInAddress,
        },
        address2: {
            required: false,
            noEmoji,
            noSpecialCharsInAddress,
        },
        city: {
            required: true,
            noEmoji,
            noSpecialCharsInAddress,
        },
        state: {
            required: true,
        },
        postal_code: {
            required: true,
            isZip5Digit,
            noEmoji,
        },
        country: {
            required: true,
            noEmoji,
        },
    },
    promocode: {
        code: {
            required: true,
            noEmoji,
        },
        purchase_submission_id: {
            required: true,
        },
    },
    deletePromocode: {
        purchase_submission_id: {
            required: true,
        },
    },
    promocodeEntityUpload: {
        type: {
            required: true,
            isIn: ['sap', 'scg_key'],
        },
        value: {
            required: true,
        },
    },
    escalate: {
        first_name: {
            required: true,
            noEmoji,
        },
        last_name: {
            required: true,
            noEmoji,
        },
        email: {
            required: true,
            external: [isEmail],
            noEmoji,
        },
        sap: {
            noEmoji,
            isEscalateSAPRequired,
        },
        contact_type: {
            required: true,
        },
        rewards_submission_type: {
            required: true,
        },
        reason_for_inquiry: {
            required: true,
        },
        patient_first_name: {
            required: true,
            noEmoji,
        },
        patient_last_initial: {
            required: true,
            external: [isLength, { min: 1, max: 1 }],
            noEmoji,
        },
        patient_contact_email: {
            external: [isEmail],
            validateEscalatePatientContactField,
        },
        patient_contact_phone: {
            phoneValidator,
            validateEscalatePatientContactField,
        },
        patient_order_number: {
            required: false,
            noEmoji,
        },
        contact_preference: {
            required: true,
        },
    },
    groupAffiliationSusLevel: {
        comments: {
            required: true,
        },
    },
    adminEmailTrigger: {
        emailAddress: {
            required: true,
            external: [isEmail],
            noEmoji,
        },
    },
    invoiceSupport: {
        fulfillmentType: {
            required: true,
        },
        jiraId: {
            required: true,
            isJiraId,
        },
        invoiceDate: {
            required: true,
            isValidDateFormat,
        },
    },
    adminPromocodePromotion: {
        jira_issue_id: {
            required: true,
        },
        promotion_name: {
            required: true,
            isAlphanumericWithUnderscore,
        },
        display_name: {
            required: true,
        },
        start_date: {
            required: true,
            external: [isISO8601],
        },
        end_date: {
            required: true,
            external: [isISO8601],
        },
        eligible_brands: {
            required: true,
        },
        patient_types: {
            required: true,
            isValidPatientType,
        },
        entry_types: {
            required: true,
            isValidEntryType,
        },
        both_eyes: {
            required: true,
            isValidBothEyes,
        },
        award: {
            required: true,
        },
    },
    adminPromocodePromoName: {
        promotion_name: {
            required: true,
        },
    },
    adminPromocodeClass: {
        promotion_name: {
            required: true,
        },
        code_class: {
            required: true,
        },
        code: {
            required: true,
            isAlphanumericWithUnderscore,
        },
    },
    adminProfileExport: {
        onetrust_id: {
            required: true,
            alphaNumericTenChar,
        },
    },
    adminException: {
        exception_id: {
            required: true,
            isAlphanumericHyphen,
        },
    },
    optIn: {
        primary_opt_in: {
            required: true,
        },
        primary_opt_in_source: {
            required: true,
            isIn: ['estimator', 'sso'],
        },
    },
};


const viralGuards = module.exports.viral;

if (displayCAConfirm) {
    viralGuards.taf_confirm = { required: true, isChecked: true };
}

range(1, maxFields + 1).forEach((fieldNum) => {
    viralGuards['to_name' + fieldNum] = {
        ...viralGuards['to_name' + fieldNum],

        requiresField: 'to_email' + fieldNum,
    };
    viralGuards['to_email' + fieldNum] = {
        ...viralGuards['to_email' + fieldNum],

        requiresField: 'to_name' + fieldNum,
        external: [isEmail],
    };
});

function getFilledToEmailFields (obj) {
    return  Object.entries(obj)
    .filter(([key]) => key.startsWith('to_email'))
    // Filter out blanks.
    .filter(([key, email]) => !!email);
}

function isUserGenerated (value, key, obj) {
    const validation = this;

    let valid = true;
    if (parseInt(value) === 999) {
        if (!isFilled(obj.first_name)) {
            validation.addError('first_name', 'NULL');
            valid = false;
        }
        if (!isFilled(obj.last_name)) {
            validation.addError('last_name', 'NULL');
            valid = false;
        }
    }
    return valid;
}

function isValidDateFormat (value, key, obj) {
    if (key === 'checkout_shop_start' && value == undefined) {
        // work around for not requiring checkout_shop_start field
        return true;
    }
    if (moment(value, 'MM/DD/YYYY').isValid()) {
        return true;
    }
    else {
        return false;
    }
}

function isValidECPStatus (value, key, obj) {
    const validation = this;
    // this field was not included
    if (value == undefined) {
        return true;
    }
    const validStatuses = ['active', 'inactive'];
    if (validStatuses.includes(value)) {
        return true;
    }
    validation.addError('status', 'VALIDATION', 'invalid status');
    return false;
}

function isValidECPPeriod (value, key, obj) {
    const validation = this;

    if (validPrograms.includes(value.toLowerCase())) {
        return true;
    }
    validation.addError('period_name', 'VALIDATION', 'invalid period name');
    return false;
}

function isValidUnaffiliatedStatus (value, key, obj) {
    const validation = this;
    const isInArray = [true, false];
    if (value == undefined) {
        // this is an optional field
        return true;
    }
    if (isInArray.includes(value)) {
        return true;
    }
    validation.addError('unaffiliated', 'VALIDATION', 'invalid unaffiliated status');
    return false;
}

function isRelationToProfileSelected (value) {
    return value != 'PENDING';
}

function isSupplyAValidNumber (value, key, obj) {
    // Checks for anything falsy like 0 or less than 0 in value
    const supplyNumber = Number(obj.supply);
    if (obj.supply && (supplyNumber <= 0 || supplyNumber > 12)) {
        return false;
    }
    if (obj.supply && !Number.isInteger(supplyNumber)) {
        return false;
    }
    return true;
}

function validateEscalatePatientContactField (value, key, obj) {
    const validation = this;

    if (!obj.patient_contact_email && !obj.patient_contact_phone) {
        validation.addError(key, 'VALIDATION', 'Patient Email or Phone Required');
        return false;
    }

    return true;
}

function isEscalateSAPRequired (value, key, obj) {
    const validation = this;
    if (obj.reason_for_inquiry == 'ecp_ineligible') {
        if (!value) {
            validation.addError('sap', 'NULL');
            return false;
        }

        if (!isNumber.test(value)) {
            validation.addError('sap', 'VALIDATION', 'SAP must be an integer');
            return false;
        }
    }

    return true;
}

function isValidEntryType (value, key, obj) {
    const validation = this;

    const validTypes = new Set(['checkout', 'shop', 'site']);

    if (!Array.isArray(obj.entry_types)) {
        validation.addError('entry_types', 'VALIDATION', 'Not an array');
        return false;
    }

    for (const type of obj.entry_types) {
        if (!validTypes.has(type.toLowerCase())) {
            validation.addError('entry_types', 'VALIDATION', 'Not a valid entry type');
            return false;
        }

    }

    return true;
}

function isValidPatientType (value, key, obj) {
    const validation = this;

    const validTypes = new Set(['new_acuvue', 'new_product', 'current_wearer']);

    if (!Array.isArray(obj.patient_types)) {
        validation.addError('patient_types', 'VALIDATION', 'Not an array');
        return false;
    }

    for (const type of obj.patient_types) {
        if (!validTypes.has(type.toLowerCase())) {
            validation.addError('patient_types', 'VALIDATION', 'Not a valid patient type');
            return false;
        }
    }

    return true;
}

function isValidBothEyes (value, key, obj) {
    const validation = this;

    if (!(typeof obj.both_eyes === 'boolean')) {
        validation.addError('both_eyes', 'VALIDATION', 'Not a boolean');
        return false;
    }

    return true;
}

function areValidSupplies (value, key, obj) {
    const validation = this;

    const brands = new Set([
        '1DM',
        '1MM',
        '1MA',
        '1OH',
        'OWT',
        'ATL',
        'AAS',
        'AUP',
        'CYP',
        'TRP',
        'TAS',
        'PH',
        'FAL',
    ]);

    const supplyLengths = new Set([3, 6, 9, 12]);

    if (!Array.isArray(obj.eligible_brands)) {
        validation.addError('eligible_brands', 'VALIDATION', 'Not an array');
        return false;
    }

    for (const brand in obj.eligible_brands) {
        if (!brands.has(brand)) {
            validation.addError('eligible_brands', 'VALIDATION', 'Not a valid brand');
            return false;
        }

        for (const supplyLength of obj.eligible_brands[brand]) {
            if (!supplyLengths.has(supplyLength)) {
                validation.addError('eligible_brands', 'VALIDATION', 'Not a valid supply length');
                return false;
            }
        }
    }

    return true;
}
