import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal non-participating",
  role: "dialog"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay general-modal",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString($setup.t('non_participating.headline_copy')), 1),
      _createElementVNode("div", {
        class: "non-participating-copy",
        innerHTML: $setup.t('non_participating.body_copy')
      }, null, 8, _hoisted_2),
      _createElementVNode("div", {
        class: "non-participating-disclaimer",
        innerHTML: $options.disclaimer
      }, null, 8, _hoisted_3),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "icon-close",
          alt: $setup.t('buttons:close')
        }, null, 8, _hoisted_4)
      ])
    ])
  ]))
}