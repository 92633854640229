
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { capitalize } from 'lodash';
    import BaseSelect from '@components/form/BaseSelect.vue';
    import NotListedTooltip from '@components/tooltips/NotListedTooltip.vue';
    import PreferredTooltip from '@components/tooltips/PreferredTooltip.vue';
    import LenscraftersTooltip from '@components/tooltips/LenscraftersTooltip.vue';
    import UnaffiliatedEcpTooltip from '@components/tooltips/UnaffiliatedEcpTooltip.vue';
    import EyecareProviderSelectedModalVue from './EyecareProviderSelectedModal.vue';
    import BaseModal from './BaseModal';
    import openModal from './';

    const eligibleBonusPrograms = {"_public":true,"affiliationPeriods":["2020_q1","2020_q2","2020_q3","2020_q4","2021_q1","2021_q2","2021_q3","2021_q4","2022_q1","2022_q2","2022_q3","2022_q4","2023_q1","2023_q2","2023_q3","2023_q4","2024_q1","2024_q2","2024_q3","2024_q4"],"preferredPracticePrograms":["preferredpractice","pilot","trial","2020_q1","2020_q2","2020_q3","2020_q4","2021_q1","2021_q2","2021_q3","2021_q4","2022_q1","2022_q2","2022_q3","2022_q4","2023_q1","2023_q2","2023_q3","2023_q4","2024_q1","2024_q2","2024_q3","2024_q4","2025_q1","2025_q2","2025_q3","2025_q4"],"allPrograms":["preferredpractice","pilot","trial","2020_q1","2020_q2","2020_q3","2020_q4","2021_q1","2021_q2","2021_q3","2021_q4","2022_q1","2022_q2","2022_q3","2022_q4","2023_q1","2023_q2","2023_q3","2023_q4","2024_q1","2024_q2","2024_q3","2024_q4","2025_q1","2025_q2","2025_q3","2025_q4","checkout_shop","oasys_max_pilot"]}.preferredPracticePrograms;


    export default {
        name: 'EyeCareProviderModal',

        components: {
            BaseSelect,
            NotListedTooltip,
            PreferredTooltip,
            LenscraftersTooltip,
            UnaffiliatedEcpTooltip,
        },

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['patient_entry', 'eye_care_provider_search', 'global', 'links']);
            return { t };
        },

        data () {
            return {
                modalName: 'eyecare_provider_search',
                postal_code_radius: undefined,
                filter: undefined,
            };
        },

        computed: {
            ...mapGetters({
                isAdminImpersonation: 'profile/isAdminImpersonation',
                ecpForm: 'patient/getEyeCareProfessional',
                ecpList: 'patient/getEyeCareProfessionalList',
                patientInfo: 'patient/getCurrentPatientInfo',
                hasPreferredPracticeInList: 'patient/hasPreferredPracticeInList',
            }),
            filteredList () {
                const { ecpList } = this;
                if (this.filter === undefined || this.filter.length === 0) return ecpList;

                return ecpList.filter((element) => {
                    const keys = ['display_name', 'account_name', 'address1', 'address2', 'address3', 'city'];
                    let found = 0;

                    keys.forEach(key => {
                        if (element[key] && element[key].includes(this.filter.toUpperCase())) {
                            found++;
                        }
                    });

                    return found > 0;
                });
            },
            radiusOptions () {
                const values = this.t('radius_options');
                if (!values) return [];

                const selectValues = Object.keys(values)
                    .filter(value => !(/^\./).test(value))
                    .map(value => ({ value: values[value].value, label: values[value].label }));
                return selectValues;
            },
        },

        watch: {
            postal_code_radius (newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.searchByRadius();
                }
            },
        },

        methods: {
            ...mapMutations({
                updateInfo: 'patient/updateInfo',
            }),
            ...mapActions({
                updateSelectedECP: 'patient/updateSelectedECP',
                submitSearchByRadius: 'patient/submitSearchByRadius',
            }),
            selectECP (ecp) {
                this.updateSelectedECP(ecp);
                this.closeModal(this);
                const preferredActive = (Object.keys(ecp.programs).length > 0) ? this.preferredActive(ecp.programs) : false;
                openModal(EyecareProviderSelectedModalVue, { preferredActive });
                try {
                    this.scrollToTop();
                }
                catch (error) {
                    console.warn('Unable to scroll');
                }
            },
            searchByRadius ()  {
                const data = {
                    eye_care_professional_postal_code: this.ecpForm.eye_care_professional_postal_code.slice(0, 5),
                    radius: this.postal_code_radius,
                };
                this.submitSearchByRadius({ params: data });
            },
            preferredActive (programs) {

                const keys = Object.keys(programs);
                const activeBonusPrograms = keys.find(key => {
                    if (eligibleBonusPrograms.includes(key.toLowerCase())) {

                        const program = programs[key];
                        const startDate = new Date(program.start_date).getTime();
                        const endDate = new Date(program.end_date).getTime();
                        const currentTime = this.$store.state.app.now;

                        return startDate <= currentTime && currentTime <= endDate;
                    }
                    return false;

                });
                return activeBonusPrograms !== undefined;
            },
            isIneligibleLenscrafters (ineligibleCheckArray) {
                const currentTime = this.$store.state.app.now;
                if (ineligibleCheckArray) {
                    const array = ineligibleCheckArray
                        .filter(element => element.label === 'lenscrafters' && new Date(element.end_date) > currentTime && new Date(element.start_date) < currentTime);
                    return array.length > 0;
                }
                else {
                    return false;
                }
            },
            cannotFindDoctor () {
                this.updateInfo({
                    cannot_find: true,
                    cannot_find_form: false,
                });
                this.updateSelectedECP({ sap: '999' });
                this.closeModal();
            },
            handleCase (text) {
                // This makes use of 2 lodash methods. The overall effect is to create
                // "Title Case" phrases from phrases no matter what their original
                // capitalization may be.
                return text.replaceAll(/\w+/g, capitalize);
            },
        },
    };
